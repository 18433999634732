@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.documentindex-container {
    align-items: left;
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
    font-weight: 400;
    color: #333;
    margin-top: 2.2rem;
    margin-bottom: 0rem;
}

h1 {
    margin-top: 0rem;
}

/* Set font size across site depending on DPI of screen */
html {
    font-size: 16px;
}

@media ((-webkit-min-device-pixel-ratio: 1.75), (min-resolution: 168dpi)) and (min-width: 768px) {
    html {
        font-size: 14px;
    }
}

@media ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)) and (min-width: 768px) {
    html {
        font-size: 12px;
    }
}
