@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
body {
  overflow-y: scroll;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.documentindex-container {
    -webkit-align-items: left;
            align-items: left;
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
    font-weight: 400;
    color: #333;
    margin-top: 2.2rem;
    margin-bottom: 0rem;
}

h1 {
    margin-top: 0rem;
}

/* Set font size across site depending on DPI of screen */
html {
    font-size: 16px;
}

@media ((-webkit-min-device-pixel-ratio: 1.75), (-webkit-min-device-pixel-ratio: 1.75)) and (min-width: 768px), ((-webkit-min-device-pixel-ratio: 1.75), (min-resolution: 168dpi)) and (min-width: 768px) {
    html {
        font-size: 14px;
    }
}

@media ((-webkit-min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2)) and (min-width: 768px), ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)) and (min-width: 768px) {
    html {
        font-size: 12px;
    }
}

